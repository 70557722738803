import React from 'react'
import {CounterLabelProps} from '../../../entities/PropEntities'

const CounterLabel = ({text, totalCount, completedCount, color, textColor}: CounterLabelProps) => {
  return (
    <div>
      <div className='d-flex align-items-center'>
        <text className={` fs-7 ` + textColor}>{text}</text>

        <div className={`ms-2 py-1 px-3 counter-label-button fs-8 ` + color}>
          {completedCount}/{totalCount}
        </div>
      </div>
    </div>
  )
}

export default CounterLabel
