import StudentDetailsContainer from './StudentDetailsContainer'
import {useState} from 'react'
import CreateStudent from '../createStudent/CreateStudent'
import {STUDENT_PAGE_HEADING, ADD_BUTTON, EDIT} from '../../../../constants/AdminConstants'
import {useParams} from 'react-router-dom'
import EditStudent from '../EditStudent/EditStudent'
import IconSquareButton from '../../reusableComponents/IconSquareButton'

const StudentDetailPage = () => {
  const [showCreateStudentModal, setShowCreateStudentModal] = useState(false)
  const [showEditStudentModal, setShowEditStudentModal] = useState(false)
  const {id} = useParams()
  const studentId = parseInt(id!)

  const toggleCreateStudentModal = () => {
    setShowCreateStudentModal(!showCreateStudentModal)
  }

  const toggleEditStudentModal = () => {
    setShowEditStudentModal(!showEditStudentModal)
  }

  return (
    <div>
      <div className='row'>
        <div className='col-4'>
          <h1>{STUDENT_PAGE_HEADING}</h1>
        </div>
        {/*Col-8 Starts here */}
        <div className='col-8 d-flex justify-content-end '>
          <div className='px-5'>
            <IconSquareButton
              path='/media/icons/duotune/add-svgrepo-com.svg'
              title='Click to add a student'
              onClick={toggleCreateStudentModal}
              textButton={ADD_BUTTON}
            />
          </div>
          <IconSquareButton
            path='/media/icons/duotune/pen-to-square-regular.svg'
            title='Click to Edit a student'
            onClick={toggleEditStudentModal}
            textButton={EDIT}
          />
        </div>
        {/*Col-8 End here */}
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center mt-5 '>
        <StudentDetailsContainer studentId={studentId} />
      </div>

      <EditStudent
        show={showEditStudentModal}
        handleClose={toggleEditStudentModal}
        userIdByRole={studentId}
      />

      <CreateStudent show={showCreateStudentModal} handleClose={toggleCreateStudentModal} />
    </div>
  )
}
export default StudentDetailPage
