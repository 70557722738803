import {useMutation} from '@tanstack/react-query'
import APIClientHooks from '../../services/api-client-hooks'
import {
  PASSWORD_CREATION_FAILED,
  PASSWORD_CREATION_SUCCESS,
} from '../../constants/ErrorMessagesConstants'
import {useNavigate} from 'react-router-dom'

export interface CreatePassword {
  auth_token: string
  password: string
}

const useCreatePassword = () => {
  const apiClient = new APIClientHooks('/auth/create-password')
  const navigate = useNavigate() // For Navigation to login once created

  return useMutation({
    mutationFn: (data: CreatePassword) => apiClient.postData(data),
    onSuccess: (data) => {
      const message = PASSWORD_CREATION_SUCCESS
      alert(message)
      navigate('/')
    },
    onError: () => {
      alert(PASSWORD_CREATION_FAILED)
    },
  })
}

export default useCreatePassword
