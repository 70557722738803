import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {useFetchAllClassesOfStudentByUserId} from '../../hooks/useStudentClass'
import {ClassCards} from '../classes/ClassCards'
import {Spinner} from 'react-bootstrap'
import {PageTitleUpdater} from '../../../../_metronic/layout/components/header/PageTitleUpdater'
import {StudentClassesBreadCrumbs} from '../../../breadcrumbs/breadcrums'

//TODO: This will show all the classes
const StudentClassesPage = () => {
  const {currentUser} = useAuth()
  const {data: dataStudentClasses, isLoading: isLoadingStudentClasses} =
    useFetchAllClassesOfStudentByUserId()

  if (!currentUser?.user_role) return <div>Error: UserRole not found</div>
  if (isLoadingStudentClasses) return <Spinner />
  if (!dataStudentClasses) return <div>Sorry, there are no Classes found.</div>

  return (
    <>
      <PageTitleUpdater breadcrumbs={StudentClassesBreadCrumbs}>Classes</PageTitleUpdater>
      <div>
        <div className='d-flex justify-content-between align-items-center '>
          <h1 className='my-0'>{currentUser.name} Classes</h1>
        </div>

        <div className='row row-cols-1  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-5 mt-5'>
          {dataStudentClasses?.map((classdata) => (
            <div className='col' key={classdata.id}>
              <Link to='/home'>
                <ClassCards
                  name={classdata.Class?.name ? classdata.Class.name : ' '}
                  classId={classdata.classId}
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default StudentClassesPage
