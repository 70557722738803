import {useForm} from 'react-hook-form'
import {ModalToggleUser} from '../../../entities/UserEnities'
import CustomInput, {
  createZodValidationRule,
  createZodValidationRuleForOptional,
} from '../teacher/createteacher/CustomInput'
import {schemaName, schemaSelectedOptional} from '../../../schema/createUserSchema'
import {Modal, Spinner} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {ADD_BUTTON, CREATE_A_NEW_CLASS, SELECT_A_TEACHER} from '../../../constants/AdminConstants'
import {useFetchAllTeachers} from '../../hooks/useTeacher'
import {useCreateClass} from '../../hooks/useClass'
import {
  TEACHER_DETAILS_NOT_FOUND,
  UNEXPECTED_ERROR,
} from '../../../constants/ErrorMessagesConstants'
import CustomSelectSingleInput from '../reusableComponents/CustomSelectSingleInput'

//TODO: This will create a class.
const CreateClass = ({show, handleClose}: ModalToggleUser) => {
  const {
    control, // RHF control
    handleSubmit,
    setValue,
    formState: {errors},
    reset,
  } = useForm()

  const {data: teachersData} = useFetchAllTeachers()
  const {mutate} = useCreateClass()

  const onSubmit = (data: any) => {
    mutate(data)
    reset()
    handleClose()
  }

  let teacherOptions = teachersData?.map((teacher) => ({
    value: teacher.id.toString(),
    label: teacher.User.name,
  }))
  if (teacherOptions === undefined) {
    teacherOptions = [
      {
        value: '',
        label: TEACHER_DETAILS_NOT_FOUND,
      },
    ]
  }

  return (
    <>
      <Modal
        className='modal fade'
        id='kt_modal_create_teacher'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={show}
        dialogClassName='modal-l'
        aria-hidden='true'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header '>
            <h3 className='modal-title '>{CREATE_A_NEW_CLASS}</h3>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div className='modal-body '>
            <form>
              <CustomSelectSingleInput
                setValue={setValue}
                labelText='Select a Teacher'
                control={control}
                fieldName='teacherId'
                rules={createZodValidationRuleForOptional(schemaSelectedOptional, 'teacherId')}
                errorMessage={errors['teacherId'] ? errors['teacherId']?.message?.toString() : ''}
                options={teacherOptions}
                selectStatement={SELECT_A_TEACHER}
              />

              <CustomInput<string>
                labelText='Class Name'
                type='text'
                control={control}
                placeholder={'Enter Name'}
                name='name' // Field name
                rules={createZodValidationRule(schemaName, 'name')}
                errorMessage={errors['name'] ? errors['name']?.message?.toString() : ''}
              />

              <div className='d-flex justify-content-center mt-5 pt-5'>
                <button
                  type='button'
                  className='btn btn-light-primary '
                  onClick={handleSubmit(onSubmit)}
                >
                  {ADD_BUTTON}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreateClass
