import {Modal, Spinner} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers/components/KTSVG'
import {useForm} from 'react-hook-form'
import CustomInput, {
  createZodValidationMultipleRule,
  createZodValidationRule,
} from '../createteacher/CustomInput'
import {
  schemaArrayOfStrings,
  schemaDescription,
  schemaEmail,
  schemaName,
} from '../../../../schema/createUserSchema'
import {useFetchTeacherById, useUpdateTeacher} from '../../../hooks/useTeacher'
import {
  EDIT_TEACHER,
  NO_UNASSIGNED_CLASS,
  SELECT_A_CLASS,
  SUBMIT,
} from '../../../../constants/AdminConstants'
import {ModalToggleUser} from '../../../../entities/UserEnities'
import {UNEXPECTED_ERROR} from '../../../../constants/ErrorMessagesConstants'
import {useFetchAllClassesByTeacherIdAndAvailable} from '../../../hooks/useClass'
import CustomSelectInput from '../../reusableComponents/CustomSelectInput'

const EditTeacher = ({show, handleClose, userIdByRole}: ModalToggleUser) => {
  const teacherId = userIdByRole!
  const {data: dataTeacher, isLoading: isLoadingTeacher} = useFetchTeacherById(teacherId)
  const {mutate, isError, isSuccess} = useUpdateTeacher(teacherId)
  const {data: dataClasses} = useFetchAllClassesByTeacherIdAndAvailable(teacherId)

  const {
    control, // RHF control
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()

  const onSubmit = (data: any) => {
    mutate(data)
    handleClose()
  }
  //get all the Classes
  let classOptions
  classOptions = dataClasses?.map((classData) => ({
    value: classData.id.toString(),
    label: classData.name,
  }))

  if (classOptions === undefined) {
    classOptions = [
      {
        value: '',
        label: 'No Data Found',
      },
    ]
  }

  // Filter classes with teacherId matching userIdByRole
  const filteredClasses = dataClasses?.filter((classData) => classData.teacherId === teacherId)
  let allTeacherClasses = filteredClasses?.map((classData) => ({
    value: classData.id.toString(),
    label: classData.name,
  }))

  return (
    <>
      <Modal
        className='modal fade'
        id='kt_modal_create_teacher'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={show}
        dialogClassName='modal-l'
        aria-hidden='true'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header '>
            <h3 className='modal-title '>{EDIT_TEACHER}</h3>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div className='modal-body '>
            <form>
              <CustomInput<string>
                type='text'
                labelText='Name'
                placeholder={'Enter Name'}
                control={control}
                name='name'
                rules={createZodValidationRule(schemaName, 'name')}
                errorMessage={errors['name'] ? errors['name']?.message?.toString() : ''}
                value={dataTeacher && dataTeacher.User.name ? dataTeacher.User.name : ''}
              />
              <CustomInput<string>
                type='email'
                labelText='Email'
                placeholder={'Enter E-mail'}
                control={control}
                name='email'
                rules={createZodValidationRule(schemaEmail, 'email')}
                errorMessage={errors.email ? errors.email?.message?.toString() : ''}
                value={dataTeacher && dataTeacher.User.email ? dataTeacher.User.email : ''}
                readOnly={true}
              />

              <CustomInput<string>
                type='text'
                labelText='Description'
                placeholder={'Enter Description'}
                control={control}
                name='description'
                rules={createZodValidationRule(schemaDescription, 'description')}
                errorMessage={
                  errors['description'] ? errors['description']?.message?.toString() : ''
                }
                value={dataTeacher && dataTeacher.description ? dataTeacher.description : ''}
              />

              <CustomSelectInput
                setValue={setValue}
                labelText='Select a Class'
                selectStatement={SELECT_A_CLASS}
                control={control}
                fieldName='classId'
                rules={createZodValidationMultipleRule(schemaArrayOfStrings, 'classId')}
                errorMessage={errors['classId'] ? errors['classId']?.message?.toString() : ''}
                options={classOptions}
                value={allTeacherClasses}
                noOptionsText={NO_UNASSIGNED_CLASS}
              />

              <div className='d-flex justify-content-center mt-5 pt-5'>
                <button
                  type='button'
                  className='btn btn-light-primary '
                  onClick={handleSubmit(onSubmit)}
                >
                  {SUBMIT}
                </button>
              </div>
            </form>

            {/* <div id='kt_modal_select_location_map' className='map h-200px'></div> */}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditTeacher
