import React from 'react'
import {Spinner} from 'react-bootstrap'
import {MixedWidget8} from '../../../../../_metronic/partials/widgets'
import {useFetchAllTeachers} from '../../../hooks/useTeacher'

const AdminAllTeachersChart = () => {
  const {data: dataAllTeachers, isLoading: isLoadingAllTeachers} = useFetchAllTeachers()

  if (isLoadingAllTeachers) return <Spinner />

  //Fetching Chart Data for All Teacher
  if (!dataAllTeachers) return <div>Data not found</div>
  let dataAllTeachersChart: any = {
    data_X: [],
    data_Y: [],
  }
  for (const item of dataAllTeachers) {
    dataAllTeachersChart.data_Y.push(item.id)
    dataAllTeachersChart.data_X.push(item.createdAt)
  }
  return (
    <>
      <MixedWidget8
        data_X={dataAllTeachersChart.data_X}
        data_Y={dataAllTeachersChart.data_Y}
        className='card-xxl-stretch mb-xl-3'
        chartColor='success'
        chartHeight='70px'
        chartHeading={'Total Teachers'}
      />
    </>
  )
}

export default AdminAllTeachersChart
