import {useQuery} from '@tanstack/react-query'
import APIClientHooks from '../../services/api-client-hooks'
import {StudentClass, StudentsOfTeacher} from '../../entities/UserEnities'

const useFetchAllClassesOfStudentById = (id: number) => {
  const apiClient = new APIClientHooks<StudentClass>(`/studentclass/student/${id}`)
  return useQuery<StudentClass[], Error>({
    queryKey: ['studentclass'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: true,
  })
}

const useFetchAllClassesOfStudentByUserId = () => {
  const apiClient = new APIClientHooks<StudentClass>(`/studentclass/user`)
  return useQuery<StudentClass[], Error>({
    queryKey: ['studentclassUser'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: true,
  })
}

const useFetchAllStudentOfTeacherByTeacherId = (id: number) => {
  const apiClient = new APIClientHooks<StudentsOfTeacher>(`/studentclass/teacher/${id}`)
  return useQuery<StudentsOfTeacher[], Error>({
    queryKey: ['studentOfTeacher'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: true,
  })
}

export {
  useFetchAllClassesOfStudentById,
  useFetchAllClassesOfStudentByUserId,
  useFetchAllStudentOfTeacherByTeacherId,
}
