import StudentTotalClassesChart from './studentDashboard/StudentTotalClassesChart'
import StudentTotalTasksChart from './studentDashboard/StudentTotalTasksChart'

//TODO: Tasks chart remaining
const DashboardStudent = () => {
  return (
    <>
      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <StudentTotalClassesChart />
        </div>
        <div className='col-xxl-4'>
          <StudentTotalTasksChart />
        </div>
        <div className='col-xxl-4'>{/* <TeacherTotalStudentsChart teacherId={teacherId} /> */}</div>
      </div>
    </>
  )
}

export default DashboardStudent
