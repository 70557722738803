import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import APIClientHooks from '../../services/api-client-hooks'
import {Class, CountClasses} from '../../entities/ClassEntities'
import {CLASS_ADD_FAILED, CLASS_ADD_SUCCESS} from '../../constants/ErrorMessagesConstants'
import {toast} from 'react-toastify'
import {CLASS_EDIT_FAILED, CLASS_EDIT_SUCCESS} from '../../constants/ToastMessageConstants'

const useFetchCountAllClassesOfStudent = (id: number) => {
  const apiClient = new APIClientHooks<CountClasses>(`/classes/student/${id}/count`)
  return useQuery<CountClasses, Error>({
    queryKey: ['studentclasscount'],
    queryFn: apiClient.getSingleData,
    refetchOnWindowFocus: false,
  })
}

const useCreateClass = () => {
  const queryClient = useQueryClient()

  const apiClient = new APIClientHooks('/classes')
  return useMutation({
    mutationFn: (data: Class) => apiClient.postData(data),
    onSuccess: (data) => {
      toast.success(CLASS_ADD_SUCCESS)
      queryClient.refetchQueries(['classes'])
    },
    // onError: () => {
    //   toast.error(CLASS_ADD_FAILED)
    // },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message
      toast.error(errorMessage)
    },
  })
}

const useFetchAllClasses = (teacherId: number | string) => {
  const apiClient = new APIClientHooks<Class>(`/classes/?teacherId=${teacherId}`)
  return useQuery<Class[], Error>({
    queryKey: ['classes'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: true,
  })
}

const useFetchClassById = (id: number) => {
  const apiClient = new APIClientHooks<Class>(`/classes`)
  return useQuery<Class, Error>({
    queryKey: ['classes', id],
    queryFn: () => apiClient.getData(id),
    refetchOnWindowFocus: false,
  })
}

const useUpdateClass = (id: number) => {
  const queryClient = useQueryClient()

  const apiClient = new APIClientHooks<Class>('/classes')
  return useMutation({
    mutationFn: (data: Class) => apiClient.updateData(id, data),
    onSuccess: (data) => {
      toast.success(CLASS_EDIT_SUCCESS)
      queryClient.refetchQueries(['classes'])
    },
    // onError: () => {
    //   toast.error(CLASS_EDIT_FAILED)
    // },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message
      toast.error(errorMessage)
    },
  })
}

const useFetchAllClassesByTeacherIdAndAvailable = (id: number) => {
  const apiClient = new APIClientHooks<Class>(`/classes/available/teacher/${id}`)
  return useQuery<Class[], Error>({
    queryKey: ['classesavailable'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: true,
  })
}

//TODO: Remove this, and convert it into 1 HOOK with fetch all classes
const useFetchAllClassesByTeacherId = () => {
  const apiClient = new APIClientHooks<Class>(`/classes/teacher`)
  return useQuery<Class[], Error>({
    queryKey: ['classesofteacher'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: true,
  })
}

export {
  useFetchCountAllClassesOfStudent,
  useCreateClass,
  useFetchAllClasses,
  useFetchClassById,
  useUpdateClass,
  useFetchAllClassesByTeacherIdAndAvailable,
  useFetchAllClassesByTeacherId,
}
