import React from 'react'
import {useFetchAllClassesByTeacherId} from '../../../hooks/useClass'
import {Spinner} from 'react-bootstrap'
import {MixedWidget8} from '../../../../../_metronic/partials/widgets'

const TeacherTotalClassesChart = () => {
  const {data: dataAllClasses, isLoading: isLoadingAllClasses} = useFetchAllClassesByTeacherId()

  if (isLoadingAllClasses) return <Spinner />

  //Fetching Chart Data for All Students
  if (!dataAllClasses) return <div>Data not Found</div> //TODO What do do about it?
  let dataAllClassesChart: any = {
    data_X: [],
    data_Y: [],
  }
  for (const item of dataAllClasses) {
    dataAllClassesChart.data_Y.push(item.id)
    dataAllClassesChart.data_X.push(item.createdAt)
  }

  return (
    <>
      <MixedWidget8
        data_X={dataAllClassesChart.data_X}
        data_Y={dataAllClassesChart.data_Y}
        className='card-xxl-stretch mb-xl-3'
        chartColor='success'
        chartHeight='150px'
        chartHeading={'Total Classes'}
      />
    </>
  )
}

export default TeacherTotalClassesChart
