//For Multi Value Select Input
export const customStyleMultiValueSelectInput = {
  option: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: state.isSelected ? '#212529' : 'white',
    backgroundColor: '#1e1e2d',
    borderLeft: '1px solid  #4763e4',
    borderRight: '1px solid  #4763e4',
  }),

  control: (defaultStyles: any, state: any) => ({
    //for inner control styles
    ...defaultStyles,
    backgroundColor: '#1e1e2d',
    padding: '8px',
    border: state.isFocused || state.isHovered ? '1px solid  #4763e4' : '1px solid #92929f',
    borderRadius: '0.475rem',
    boxShadow: 'none',
  }),
  multiValue: (base: any, state: any) => ({
    ...base,
    backgroundColor: '#2b99f2',
    margin: '5px',
    border: state.isFocused || state.isHovered ? '1px solid  #4763e4' : '1px solid #92929f',
    borderRadius: '0.475rem',
  }),

  multiValueLabel: (base: any, state: any) => ({
    ...base,
    color: 'white', // Change the text color here
  }),

  multiValueRemove: (base: any, state: any) => ({
    ...base,
    color: 'white', // Change the color of the cross icon
    ':hover': {
      color: 'black', // Change the color on hover if needed
    },
  }),

  menu: (base: any) => ({
    ...base,

    marginTop: '5px',
    border: '1px solid  #4763e4',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
  }),
}

//For Single Value Select Input
export const customStylesSingleValueSelectInput = {
  option: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: state.isSelected ? '#212529' : 'white',
    backgroundColor:
      state.isSelected || state.isHovered || state.isActive || state.isFocused
        ? '#2b99f2'
        : '#1e1e2d',
  }),

  control: (defaultStyles: any, state: any) => ({
    //for inner control styles
    ...defaultStyles,
    backgroundColor: '#1e1e2d',
    padding: '8px',
    border: state.isFocused || state.isHovered ? '1px solid  #4763e4' : '1px solid #92929f',
    borderRadius: '0.475rem',
    boxShadow: 'none',
  }),
  singleValue: (defaultStyles: any) => ({...defaultStyles, color: '#92929f'}),
  menu: (base: any) => ({
    ...base,
    // override border radius to match the box

    // kill the gap
    marginTop: '5px',
    border: '1px solid  #4763e4',
  }),
  menuList: (base: any) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
}
