import {useFetchAllTasksOfClassByStudentId} from '../../../hooks/useTaskAndStudents'
import {Spinner} from 'react-bootstrap'
import TaskCard from '../../tasks/TaskCard'
import {useParams} from 'react-router-dom'
import {PageTitleUpdater} from '../../../../../_metronic/layout/components/header/PageTitleUpdater'
import {generateStudentClassesBreadCrumbs} from '../../../../breadcrumbs/breadcrums'
import {ALL_TASKS_BREADCRUMBS} from '../../../../constants/BreadcrumbsConstants'
import {NO_TASKS_FOR_STUDENT, STUDENT_TASKS} from '../../../../constants/AdminConstants'
//TODO: Breadcrumbs and Heading
const AllTasksOfStudent = () => {
  const {id} = useParams()
  const studentId = parseInt(id!)
  const {data: dataTasks, isLoading: isLoadingTasks} = useFetchAllTasksOfClassByStudentId(
    0,
    studentId
  ) //Make this null
  return (
    <>
      <PageTitleUpdater
        breadcrumbs={generateStudentClassesBreadCrumbs('Student Details', `/student/${studentId}`)}
      >
        {ALL_TASKS_BREADCRUMBS}
      </PageTitleUpdater>

      <div>
        <h1>{STUDENT_TASKS}</h1>
        <div>
          {isLoadingTasks ? (
            <Spinner />
          ) : (
            <div>
              {dataTasks !== undefined && dataTasks !== null && dataTasks.length > 0 ? (
                <div>
                  {dataTasks?.map((weekData) => (
                    <div className='mt-5 pt-5' key={weekData.week}>
                      <h1>Week: {weekData.week}</h1>
                      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-1 mt-5'>
                        {weekData?.tasks?.map((task) => (
                          <div className='col' key={task.id}>
                            <TaskCard
                              description={task.description}
                              taskName={task.name}
                              completedSubtasks={0}
                              completedCounter={task.TasksAndStudents?.counter || 0}
                              totalCounter={task.total_counter}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className='d-flex justify-content-center mt-20'>
                  <h3>{NO_TASKS_FOR_STUDENT}</h3>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AllTasksOfStudent
