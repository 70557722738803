import React from 'react'
import {useFetchAllStudents} from '../../../hooks/useStudent'
import {Spinner} from 'react-bootstrap'
import CardUser from '../../reusableComponents/CardUser'
import {useLocation} from 'react-router-dom'
import {NO_STUDENTS_FOUND} from '../../../../constants/AdminConstants'

const ListViewStudent = () => {
  const {
    data: dataStudent,
    isLoading: isLoadingStudent,
    error: errorStudent,
  } = useFetchAllStudents()

  const userRoleLink = '/student'

  if (isLoadingStudent) return <Spinner />
  if (!dataStudent)
    return (
      <div className='mt-20 d-flex justify-content-center'>
        <h3> {NO_STUDENTS_FOUND}</h3>
      </div>
    )

  if (errorStudent) return <div>Error occured: {errorStudent.message}</div>

  return (
    <>
      <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 g-5'>
        {dataStudent?.map((student) => (
          <div className='col' key={student.id}>
            <CardUser
              // image={student.image}
              name={student.User.name}
              description={student.description || ''} // Handle null description
              id={student.id}
              userRoleLink={userRoleLink}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default ListViewStudent
