import {Spinner} from 'react-bootstrap'
import {useFetchAllTasksOfClassByStudentId} from '../../../hooks/useTaskAndStudents'
import {MixedWidget8} from '../../../../../_metronic/partials/widgets'

const StudentTotalTasksChart = () => {
  const {data: dataTasks, isLoading: isLoadingTasks} = useFetchAllTasksOfClassByStudentId(0, 0)
  if (isLoadingTasks) return <Spinner />

  if (!dataTasks) return <div>Data not Found</div> //TODO What do do about it?
  let dataAllTasksChart: any = {
    data_X: [],
    data_Y: [],
  }
  // for (const week of dataTasks) {
  //   for (const task of week.task) {
  //     dataAllTasksChart.data_X.push(task.createdAt)
  //     dataAllTasksChart.data_Y.push(task.id)
  //   }
  // } //This gives error, remove it when sure error has been resolved
  for (const week of dataTasks) {
    if (week.tasks) {
      for (const task of week.tasks) {
        if (task && task.createdAt && task.id) {
          dataAllTasksChart.data_X.push(task.createdAt)
          dataAllTasksChart.data_Y.push(task.id)
        }
      }
    }
  }

  return (
    <>
      <MixedWidget8
        data_X={dataAllTasksChart.data_X}
        data_Y={dataAllTasksChart.data_Y}
        className='card-xxl-stretch mb-xl-3'
        chartColor='success'
        chartHeight='150px'
        chartHeading={'Total Tasks'}
      />
    </>
  )
}

export default StudentTotalTasksChart
