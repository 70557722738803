import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import APIClientHooks from '../../services/api-client-hooks'
import {CountStudents, Teacher} from '../../entities/UserEnities'
import {CountClasses} from '../../entities/ClassEntities'
import {
  TEACHER_ADD_FAILED,
  TEACHER_ADD_SUCCESS,
  TEACHER_UPDATE_FAILED,
  TEACHER_UPDATE_SUCCESS,
} from '../../constants/ErrorMessagesConstants'
import {toast} from 'react-toastify'
import {TEACHER_EDIT_SUCCESS} from '../../constants/ToastMessageConstants'
import {Toast} from 'react-toastify/dist/components'

const useCreateTeacher = () => {
  const queryClient = useQueryClient()

  const apiClient = new APIClientHooks('/teachers/')
  return useMutation({
    mutationFn: (data: Teacher) => apiClient.postData(data),
    onSuccess: (data) => {
      toast.success(TEACHER_ADD_SUCCESS)
      queryClient.refetchQueries(['teachers'])
      queryClient.refetchQueries(['classes'])
    },
    // onError: () => {
    //   toast.error(TEACHER_ADD_FAILED)
    // },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message
      toast.error(errorMessage)
    },
  })
}

const useFetchAllTeachers = () => {
  const apiClient = new APIClientHooks<Teacher>('/teachers/')
  return useQuery<Teacher[], Error>({
    queryKey: ['teachers'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: false,
  })
}

const useFetchCountAllStudentsOfTeacher = (id: number) => {
  const apiClient = new APIClientHooks<CountStudents>(`/classes/teacher/${id}/students/count`)
  return useQuery<CountStudents, Error>({
    queryKey: ['teacherstudents'],
    queryFn: apiClient.getSingleData,
    refetchOnWindowFocus: false,
  })
}

//TODO: Move this to Class Hook
const useFetchCountAllClassesOfTeacher = (id: number) => {
  const apiClient = new APIClientHooks<CountClasses>(`/classes/teacher/${id}/count`)
  return useQuery<CountClasses[], Error>({
    queryKey: ['teacherclasses'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: false,
  })
}

const useFetchTeacherById = (id: number) => {
  const apiClient = new APIClientHooks<Teacher>(`/teachers`)
  return useQuery<Teacher, Error>({
    queryKey: ['teachers', id],
    queryFn: () => apiClient.getData(id),
    // refetchOnWindowFocus: true,
  })
}

const useFetchTeacherByUserId = (id: number) => {
  const apiClient = new APIClientHooks<Teacher>(`/teachers/user`)
  return useQuery<Teacher, Error>({
    queryKey: ['teachersUser', id],
    queryFn: () => apiClient.getData(id),
    // refetchOnWindowFocus: true,
  })
}

const useUpdateTeacher = (id: number) => {
  const apiClient = new APIClientHooks<Teacher>('/teachers')
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: Teacher) => apiClient.updateData(id, data),
    onSuccess: (data) => {
      toast.success(TEACHER_UPDATE_SUCCESS)
      // Manually trigger the refetch of useFetchTeacherById
      queryClient.refetchQueries(['teachers', id])
      queryClient.refetchQueries(['classesavailable'])
      queryClient.refetchQueries(['teacherclasses'])
      queryClient.refetchQueries(['teacherstudents'])
    },
    // onError: () => {
    //   toast.error(TEACHER_UPDATE_FAILED)
    // },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message
      toast.error(errorMessage)
    },
  })
}

export {
  useCreateTeacher,
  useFetchAllTeachers,
  useFetchCountAllStudentsOfTeacher,
  useFetchCountAllClassesOfTeacher,
  useFetchTeacherById,
  useUpdateTeacher,
  useFetchTeacherByUserId,
}
