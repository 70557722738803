import {IMG_DUMMY} from '../../../../constants/AdminConstants'
import CountPillButton from '../../reusableComponents/CountPillButton'
import {useFetchCountAllTasksOfStudent} from '../../../hooks/useTask'
import {useFetchStudentById} from '../../../hooks/useStudent'
import {useFetchCountAllClassesOfStudent} from '../../../hooks/useClass'
import {Spinner} from 'react-bootstrap'
import {STUDENT_DETAILS_NOT_FOUND} from '../../../../constants/ErrorMessagesConstants'
import {PageTitleUpdater} from '../../../../../_metronic/layout/components/header/PageTitleUpdater'
import {StudentDataBreadCrumbs} from '../../../../breadcrumbs/breadcrums'
import {Link} from 'react-router-dom'

interface StudentDetailsContainerProps {
  studentId: number
}

const StudentDetailsContainer = ({studentId}: StudentDetailsContainerProps) => {
  const {
    data: dataStudent,
    error: errorStudent,
    isLoading: isLoadingStudent,
  } = useFetchStudentById(studentId)
  const {data: dataCountTasks} = useFetchCountAllTasksOfStudent(studentId!)

  const {data: dataCountClasses} = useFetchCountAllClassesOfStudent(studentId)

  if (isLoadingStudent) return <Spinner />
  if (errorStudent) return <div>{STUDENT_DETAILS_NOT_FOUND}</div>
  return (
    <>
      <PageTitleUpdater breadcrumbs={StudentDataBreadCrumbs}>
        {dataStudent ? dataStudent.User.name : 'Student Data'}
      </PageTitleUpdater>
      <div className='d-flex justify-content-center'>
        <img src={IMG_DUMMY} className='teacher-detail-image rounded-circle mt-5 ' alt='' />
      </div>
      <div className='d-flex justify-content-center'>
        <h1 className='m-5'>
          {dataStudent && dataStudent?.User.name ? dataStudent?.User.name : 'Name Not Found '}
        </h1>
      </div>
      <div className='row row-cols-1 row-cols-md-2 g-5 mx-5'>
        <div className='col d-flex justify-content-center justify-content-md-end '>
          <Link to={`/student/${dataStudent.id}/all-classes`}>
            <CountPillButton
              AbsoluteURL='/media/student/StudentClassIcon.svg'
              Text='Total Classes'
              CountData={
                dataCountClasses && dataCountClasses?.total_classes
                  ? dataCountClasses?.total_classes
                  : 0
              }
            />
          </Link>
        </div>
        <div className='col d-flex justify-content-center justify-content-md-start'>
          <Link to={`/student/${dataStudent.id}/all-tasks`}>
            <CountPillButton
              AbsoluteURL='/media/student/TasksIcon.svg'
              Text='Total Tasks'
              CountData={
                dataCountTasks && dataCountTasks[0] ? dataCountTasks[0].total_tasks || 0 : 0
              }
            />
          </Link>
        </div>
      </div>
    </>
  )
}

export default StudentDetailsContainer
