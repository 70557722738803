import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers/components/KTSVG'
import {useForm} from 'react-hook-form'
import CustomInput, {createZodValidationMultipleRule, createZodValidationRule} from './CustomInput'
import {useCreateTeacher} from '../../../hooks/useTeacher'
import {
  ADD_TEACHER,
  CREATE_A_NEW_TEACHER,
  NO_UNASSIGNED_CLASS,
  SELECT_A_CLASS,
} from '../../../../constants/AdminConstants'
import {
  schemaArrayOfStringsOptional,
  schemaDescription,
  schemaEmail,
  schemaName,
} from '../../../../schema/createUserSchema'
import {ModalToggleUser} from '../../../../entities/UserEnities'
import CustomSelectInput from '../../reusableComponents/CustomSelectInput'
import {useFetchAllClasses} from '../../../hooks/useClass'
import {useState} from 'react'

const CreateTeacher = ({show, handleClose}: ModalToggleUser) => {
  const {mutate, isError, isSuccess} = useCreateTeacher()
  const {data: dataClasses, error, isLoading} = useFetchAllClasses('null') //means data is there

  const {
    control, // RHF control
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm()
  const onSubmit = (data: any) => {
    mutate(data)
    reset()
    handleClose()
  }
  let classOptions = dataClasses?.map((classData) => ({
    value: classData.id.toString(),
    label: classData.name,
  }))

  if (classOptions === undefined) {
    classOptions = [
      {
        value: '',
        label: 'No Data Found',
      },
    ]
  }

  return (
    <>
      <Modal
        className='modal fade'
        id='kt_modal_create_teacher'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={show}
        dialogClassName='modal-l'
        aria-hidden='true'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header '>
            <h3 className='modal-title '>{CREATE_A_NEW_TEACHER}</h3>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div className='modal-body '>
            <form>
              <CustomInput<string>
                labelText='Name'
                type='text'
                control={control}
                placeholder={'Enter Name'}
                name='name' // Field name
                rules={createZodValidationRule(schemaName, 'name')}
                errorMessage={errors['name'] ? errors['name']?.message?.toString() : ''}
              />

              <CustomInput<string>
                type='email'
                labelText='Email'
                placeholder={'Enter E-mail'}
                control={control}
                name='email'
                rules={createZodValidationRule(schemaEmail, 'email')}
                errorMessage={errors.email ? errors.email?.message?.toString() : ''}
              />
              <CustomInput<string>
                type='text'
                labelText='Description'
                placeholder={'Enter Description'}
                control={control}
                name='description'
                rules={createZodValidationRule(schemaDescription, 'description')}
                errorMessage={
                  errors['description'] ? errors['description']?.message?.toString() : ''
                }
              />
              <CustomSelectInput
                setValue={setValue}
                labelText='Select a Class'
                selectStatement={SELECT_A_CLASS}
                control={control}
                fieldName='classId'
                rules={createZodValidationMultipleRule(schemaArrayOfStringsOptional, 'classId')}
                errorMessage={errors['classId'] ? errors['classId']?.message?.toString() : ''}
                options={classOptions}
                noOptionsText={NO_UNASSIGNED_CLASS}
              />

              {/* <CustomInput type='file' labelText='Image' placeholder={'Enter Image Link'} 
                control={control}
                name='image'
                rules={{
                  validate: (value) => .safeParse(value).success || 'Invalid Image',
                }}
                errorMessage={errors. ? errors.?.message?.toString() : ''}
               
               /> */}
              <div className='d-flex justify-content-center mt-5 pt-5'>
                <button
                  type='button'
                  className='btn btn-light-primary '
                  onClick={handleSubmit(onSubmit)}
                >
                  {ADD_TEACHER}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreateTeacher
