import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import APIClientHooks from '../../services/api-client-hooks'
import {CountTasks, Task, TasksGroupedByWeek} from '../../entities/UserEnities'
import {TASK_ADD_FAILED, TASK_ADD_SUCCESS} from '../../constants/ErrorMessagesConstants'
import {toast} from 'react-toastify'

const useFetchCountAllTasksOfStudent = (id: number) => {
  const apiClient = new APIClientHooks<CountTasks>(`/tasks/student/${id}/count`)
  return useQuery<CountTasks[], Error>({
    queryKey: ['studenttasks'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: false,
  })
}

const useCreateTask = () => {
  const apiClient = new APIClientHooks('/tasks')
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: Task) => apiClient.postData(data),
    onSuccess: (data) => {
      toast.success(TASK_ADD_SUCCESS)
      queryClient.refetchQueries(['tasksclass'])
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message
      toast.error(TASK_ADD_FAILED + ' ' + errorMessage)
    },
  })
}

const useFetchAllTasksOfClass = (id: number) => {
  const apiClient = new APIClientHooks<TasksGroupedByWeek>(`/tasks/class/${id}`)
  return useQuery<TasksGroupedByWeek[], Error>({
    queryKey: ['tasksclass'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: false,
  })
}

export {useFetchCountAllTasksOfStudent, useCreateTask, useFetchAllTasksOfClass}
