import React from 'react'
import {useFetchAllStudentOfTeacherByTeacherId} from '../../../hooks/useStudentClass'
import {MixedWidget8} from '../../../../../_metronic/partials/widgets'
import {Spinner} from 'react-bootstrap'

export interface TeacherStudentsProps {
  teacherId: number
}

const TeacherTotalStudentsChart = ({teacherId}: TeacherStudentsProps) => {
  const {
    data: dataAllStudent,
    isLoading: isLoadingAllStudent,
    error: errorStudent,
  } = useFetchAllStudentOfTeacherByTeacherId(teacherId)
  if (isLoadingAllStudent) return <Spinner />

  //Fetching Chart Data for All Students
  if (!dataAllStudent) return <div>Data Not Found</div> //TODO What do about this?
  let dataAllStudentsChart: any = {
    data_X: [],
    data_Y: [],
  }
  for (const item of dataAllStudent) {
    dataAllStudentsChart.data_Y.push(item.Student.id)
    dataAllStudentsChart.data_X.push(item.Student.createdAt)
  }
  return (
    <>
      <MixedWidget8
        data_X={dataAllStudentsChart.data_X}
        data_Y={dataAllStudentsChart.data_Y}
        className='card-xxl-stretch mb-xl-3'
        chartColor='success'
        chartHeight='150px'
        chartHeading={'Total Students'}
      />
    </>
  )
}

export default TeacherTotalStudentsChart
