export const metaTags_data = {
  title: 'Track a Habit',
  siteName: 'YOUR_SITE_NAME',
  description: 'YOUR_APP_DESCRIPTION',
  logo: 'YOUR_LOGO_PATH',
}

export const InvalidLogin =
  'Oops, the username or password you entered is incorrect. Please try again.'

export const ZOOM_IN = 'Zoom In'
export const ZOOM_OUT = 'Zoom Out'
export const RESET = 'Reset'
