import {Spinner} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {useFetchAllStudentOfTeacherByTeacherId} from '../../../hooks/useStudentClass'
import CardUser from '../../reusableComponents/CardUser'
import {NO_STUDENTS_OF_TEACHER, STUDENT_PAGE_HEADING} from '../../../../constants/AdminConstants'
import {UNEXPECTED_ERROR} from '../../../../constants/ErrorMessagesConstants'
import {PageTitleUpdater} from '../../../../../_metronic/layout/components/header/PageTitleUpdater'
import {generateBreadCrumbsForAllStudentsOfTeacher} from '../../../../breadcrumbs/breadcrums'
import {ALL_STUDENTS_BREADCRUMBS} from '../../../../constants/BreadcrumbsConstants'

const AllStudentsOfTeacher = () => {
  const {id} = useParams()
  const teacherId = parseInt(id!)
  const {
    data: dataStudent,
    isLoading: isLoadingStudent,
    error: errorStudent,
  } = useFetchAllStudentOfTeacherByTeacherId(teacherId)
  // const {data: dataTeacher} = useFetchTeacherById(teacherId)
  const userRoleLink = '/student'

  if (errorStudent)
    return (
      <div className='d-flex justify-content-center mt-20'>
        <h3>{UNEXPECTED_ERROR}</h3>
      </div>
    )

  return (
    <>
      {/* <PageTitleUpdater
        breadcrumbs={generateBreadCrumbsForAllStudentsOfTeacher(
          dataTeacher?.User.name || 'Teacher Details',
          `/teacher/${teacherId}`
        )}
      >
        All Classes
      </PageTitleUpdater> */}
      <PageTitleUpdater
        breadcrumbs={generateBreadCrumbsForAllStudentsOfTeacher(
          'Teacher Details',
          `/teacher/${teacherId}`
        )}
      >
        {ALL_STUDENTS_BREADCRUMBS}
      </PageTitleUpdater>

      <div>
        <div className='d-flex justify-content-between align-items-center '>
          <h1 className='my-0'> {STUDENT_PAGE_HEADING}</h1>
        </div>

        {isLoadingStudent ? (
          <Spinner />
        ) : (
          <>
            {dataStudent && dataStudent.length <= 0 ? (
              <div className='d-flex justify-content-center mt-20'>
                <h3>{NO_STUDENTS_OF_TEACHER}</h3>
              </div>
            ) : (
              <>
                <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 g-5'>
                  {dataStudent?.map((student) => (
                    <div className='col' key={student.Student.id}>
                      <CardUser
                        // image={student.image}
                        name={student.Student.User.name || 'mm'}
                        description={student.Student.description || ''} // Handle null description
                        id={student.Student.id}
                        userRoleLink={userRoleLink}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AllStudentsOfTeacher
