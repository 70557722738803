import {useState} from 'react'
import {ADD_BUTTON, ALL_CLASSES, NO_CLASSES_FOUND} from '../../../constants/AdminConstants'
import CreateClass from './CreateClass'
import {ClassCards} from './ClassCards'
import {useFetchAllClasses} from '../../hooks/useClass'
import {Spinner} from 'react-bootstrap'
import IconSquareButton from '../reusableComponents/IconSquareButton'

//TODO: This will show all the classes
const ClassesPage = () => {
  const [showCreateClassModal, setShowCreateClassModal] = useState(false)
  const {data: dataClasses, isLoading: isLoadingClasses} = useFetchAllClasses('all')
  const toggleCreateClassModal = () => {
    setShowCreateClassModal(!showCreateClassModal)
  }

  return (
    <>
      <div>
        <div className='d-flex justify-content-between align-items-center '>
          <h1 className='my-0'>{ALL_CLASSES}</h1>

          <IconSquareButton
            path='/media/icons/duotune/add-svgrepo-com.svg'
            title='Click to add a Class'
            onClick={toggleCreateClassModal}
            textButton={ADD_BUTTON}
          />
        </div>
        {isLoadingClasses ? (
          <Spinner />
        ) : (
          <>
            {dataClasses === undefined || dataClasses?.length === 0 || dataClasses === null ? (
              <div className='mt-20 d-flex justify-content-center'>
                <h3> {NO_CLASSES_FOUND}</h3>
              </div>
            ) : (
              <div className='row row-cols-1  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-5 mt-5'>
                {dataClasses?.map((classdata) => (
                  <div className='col' key={classdata.id}>
                    <ClassCards name={classdata.name} classId={classdata.id} />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <CreateClass show={showCreateClassModal} handleClose={toggleCreateClassModal} />
      </div>
    </>
  )
}

export default ClassesPage
