import {useState} from 'react'
import CreateStudent from './createStudent/CreateStudent'
import {ADD_BUTTON, CREATE_STUDENT, STUDENT_PAGE_HEADING} from '../../../constants/AdminConstants'
import {KTSVG} from '../../../../_metronic/helpers'
import ListViewStudent from './ListViewStudents/ListViewStudent'
import IconSquareButton from '../reusableComponents/IconSquareButton'

const StudentPage = () => {
  const [showCreateStudentModal, setShowCreateStudentModal] = useState(false)

  const toggleCreateStudentModal = () => {
    setShowCreateStudentModal(!showCreateStudentModal)
  }
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h1>{STUDENT_PAGE_HEADING}</h1>

        <IconSquareButton
          path='/media/icons/duotune/add-svgrepo-com.svg'
          title='Click to add a teacher'
          onClick={toggleCreateStudentModal}
          textButton={ADD_BUTTON}
        />
      </div>
      <CreateStudent show={showCreateStudentModal} handleClose={toggleCreateStudentModal} />
      <ListViewStudent />
    </div>
  )
}

export default StudentPage
