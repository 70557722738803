import {useState} from 'react'
import {ADD_BUTTON, EDIT, TEACHER_PAGE_HEADING} from '../../../../constants/AdminConstants'
import {KTSVG} from '../../../../../_metronic/helpers/components/KTSVG'
import CreateTeacher from '../createteacher/CreateTeacher'
import TeacherDetailsContainer from './TeacherDetailsContainer'
import EditTeacher from '../EditTeacher/EditTeacher'
import {useParams} from 'react-router-dom'
import IconSquareButton from '../../reusableComponents/IconSquareButton'

//TODO For error check if no
const TeacherDetailPage = () => {
  const [showCreateTeacherModal, setShowCreateTeacherModal] = useState(false)
  const [showEditTeacherModal, setShowEditTeacherModal] = useState(false)
  const {id} = useParams()
  const teacherId = parseInt(id!)

  const toggleCreateTeacherModal = () => {
    setShowCreateTeacherModal(!showCreateTeacherModal)
  }

  const toggleEditTeacherModal = () => {
    setShowEditTeacherModal(!showEditTeacherModal)
  }

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-4'>
            <h1>{TEACHER_PAGE_HEADING}</h1>
          </div>
          {/*Col-8 Starts here */}
          <div className='col-8 d-flex justify-content-end'>
            <div className='px-5'>
              <IconSquareButton
                path='/media/icons/duotune/add-svgrepo-com.svg'
                title='Click to add a Teacher'
                onClick={toggleCreateTeacherModal}
                textButton={ADD_BUTTON}
              />
            </div>
            <IconSquareButton
              path='/media/icons/duotune/pen-to-square-regular.svg'
              title='Click to Edit a Teacher' //What does it do?
              onClick={toggleEditTeacherModal}
              textButton={EDIT}
            />
          </div>
          {/*Col-8 End here */}
        </div>

        <EditTeacher
          show={showEditTeacherModal}
          handleClose={toggleEditTeacherModal}
          userIdByRole={teacherId}
        />

        <CreateTeacher show={showCreateTeacherModal} handleClose={toggleCreateTeacherModal} />
      </div>
      <TeacherDetailsContainer teacherId={teacherId} />
    </>
  )
}

export default TeacherDetailPage
