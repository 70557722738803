import {ModalToggleUser} from '../../../entities/UserEnities'
import {toast} from 'react-toastify'
import {useForm} from 'react-hook-form'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import CustomInput, {
  createZodValidationRequiredMultipleRule,
  createZodValidationRule,
  createZodValidationRuleNumbers,
} from '../teacher/createteacher/CustomInput'
import {
  schemaArrayOfWeek,
  schemaCounter,
  schemaDescription,
  schemaName,
} from '../../../schema/createUserSchema'
import {ADD_TASK, CREATE_A_NEW_TASK} from '../../../constants/AdminConstants'
import {useCreateTask} from '../../hooks/useTask'
import {SELECT_ATLEAST_ONE} from '../../../constants/ErrorMessagesConstants'
import {useParams} from 'react-router-dom'
import CustomSelectInput from '../reusableComponents/CustomSelectInput'

const CreateTask = ({show, handleClose}: ModalToggleUser) => {
  const {mutate, isError, isSuccess} = useCreateTask()
  // const {data: dataClasses, error, isLoading: isLoadingClasses} = useFetchAllClasses('all')
  const {id} = useParams()

  const {
    control, // React-Hook-Form/RHF control
    handleSubmit,
    setValue,
    reset,
    formState: {errors, touchedFields},
  } = useForm()

  const onSubmit = async (data: any) => {
    const datanew = {...data, classId: id}

    if (datanew.week !== undefined && datanew.week.length > 0) {
      mutate(datanew)

      await reset({
        week: [],
        name: '',
        description: '',
        total_counter: 0,
      })

      handleClose()
    } else {
      toast.error(SELECT_ATLEAST_ONE)
    }
  }
  const weekArray = [1, 2, 3, 4, 5, 6, 7]
  let weekOptions = weekArray?.map((week) => ({
    value: week.toString(),
    label: 'Week ' + week,
  }))

  return (
    <>
      <Modal
        className='modal fade'
        id='kt_modal_select_location'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={show}
        dialogClassName='modal-l'
        aria-hidden='true'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <div className='modal-header '>
            <h3 className='modal-title '>{CREATE_A_NEW_TASK}</h3>

            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={handleClose}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div className='modal-body '>
            <form>
              <CustomInput<string>
                labelText='Title'
                type='text'
                control={control}
                placeholder={'Enter Title'}
                name='name' // Field name
                rules={createZodValidationRule(schemaName, 'name')}
                errorMessage={errors['name'] ? errors['name']?.message?.toString() : ''}
              />

              <CustomInput<string>
                type='text'
                labelText='Description'
                placeholder={'Enter Description'}
                control={control}
                name='description'
                rules={createZodValidationRule(schemaDescription, 'description')}
                errorMessage={
                  errors['description'] ? errors['description']?.message?.toString() : ''
                }
              />

              <CustomInput<number>
                type='number'
                labelText='Counter'
                placeholder={'Enter Counter'}
                control={control}
                name='total_counter' // Field name
                rules={createZodValidationRuleNumbers(schemaCounter, 'total_counter')}
                errorMessage={
                  errors['total_counter'] ? errors['total_counter']?.message?.toString() : ''
                }
                maxNumber={100}
              />

              {/* <CustomInput<number>
                type='number'
                labelText='Week'
                placeholder={'Enter Week'}
                control={control}
                name='week' // Field name
                rules={createZodValidationRuleNumbers(schemaWeek, 'week')}
                errorMessage={errors['week'] ? errors['week']?.message?.toString() : ''}
                maxNumber={7}
              /> */}

              <CustomSelectInput
                setValue={setValue}
                labelText='Select a week'
                selectStatement={'Select a Week'}
                control={control}
                fieldName='week'
                rules={createZodValidationRequiredMultipleRule(schemaArrayOfWeek, 'week')}
                errorMessage={errors['week'] ? SELECT_ATLEAST_ONE : ''}
                options={weekOptions}
              />

              {/* 
              <CustomSelectSingleInput
                setValue={setValue}
                labelText='Select a Class'
                selectStatement={SELECT_A_CLASS}
                control={control}
                fieldName='classId'
                rules={createZodValidationRule(schemaSelectedOptionRequired, 'classId')}
                errorMessage={errors['classId'] ? errors['classId']?.message?.toString() : ''}
                options={classOptions}
              /> */}

              <div className='d-flex justify-content-center mt-5 pt-5'>
                <button
                  type='button'
                  className='btn btn-light-primary '
                  onClick={handleSubmit(onSubmit)}
                >
                  {ADD_TASK}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreateTask
