import {useFetchAllClassesOfStudentById} from '../../../hooks/useStudentClass'
import {ClassCards} from '../../classes/ClassCards'
import {useParams} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import {PageTitleUpdater} from '../../../../../_metronic/layout/components/header/PageTitleUpdater'
import {generateStudentClassesBreadCrumbs} from '../../../../breadcrumbs/breadcrums'
import {ALL_CLASSES, NO_CLASSES_FOR_STUDENT} from '../../../../constants/AdminConstants'
import {ALL_CLASSES_BREADCRUMBS} from '../../../../constants/BreadcrumbsConstants'

const AllClassesOfStudent = () => {
  const {id} = useParams()
  const studentId = parseInt(id!)
  const {data: dataStudentClasses, isLoading: isLoadingStudentClasses} =
    useFetchAllClassesOfStudentById(studentId)
  // const {data: dataStudent} = useFetchStudentById(studentId)

  return (
    <>
      {/* <PageTitleUpdater
        breadcrumbs={generateStudentClassesBreadCrumbs(
          dataStudent?.User.name || ' ',
          `/student/${dataStudent?.id}`
        )}
      >
        All Classes
      </PageTitleUpdater> */}

      <PageTitleUpdater
        breadcrumbs={generateStudentClassesBreadCrumbs('Student Details', `/student/${studentId}`)}
      >
        {ALL_CLASSES_BREADCRUMBS}
      </PageTitleUpdater>
      <div>
        <div className='d-flex justify-content-between align-items-center '>
          <h1 className='my-0'>{ALL_CLASSES}</h1>
        </div>

        {isLoadingStudentClasses ? (
          <Spinner />
        ) : (
          <>
            {dataStudentClasses === undefined ||
            dataStudentClasses?.length === 0 ||
            dataStudentClasses === null ? (
              <div className='mt-20 d-flex justify-content-center'>
                <h3> {NO_CLASSES_FOR_STUDENT}</h3>
              </div>
            ) : (
              <div className='row row-cols-1  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-5 mt-5'>
                {dataStudentClasses?.map((classdata) => (
                  <div className='col' key={classdata.id}>
                    <ClassCards
                      name={classdata.Class?.name ? classdata.Class.name : ' '}
                      classId={classdata.classId}
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AllClassesOfStudent
