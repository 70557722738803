import ListViewTeacher from './ListViewTeachers/ListViewTeacher'
import CreateTeacher from './createteacher/CreateTeacher'
import {useState} from 'react'
import {ADD_BUTTON, TEACHER_PAGE_HEADING} from '../../../constants/AdminConstants'
import IconSquareButton from '../reusableComponents/IconSquareButton'

const TeacherPage = () => {
  const [showCreateTeacherModal, setShowCreateTeacherModal] = useState(false)

  const toggleCreateTeacherModal = () => {
    setShowCreateTeacherModal(!showCreateTeacherModal)
  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h1>{TEACHER_PAGE_HEADING}</h1>
        <IconSquareButton
          path='/media/icons/duotune/add-svgrepo-com.svg'
          title='Click to add a Teacher'
          onClick={toggleCreateTeacherModal}
          textButton={ADD_BUTTON}
        />
      </div>
      <CreateTeacher show={showCreateTeacherModal} handleClose={toggleCreateTeacherModal} />
      <ListViewTeacher />
    </div>
  )
}

export default TeacherPage
