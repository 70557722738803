import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import APIClientHooks from '../../services/api-client-hooks'
import {Student} from '../../entities/UserEnities'
import {
  STUDENT_ADD_FAILED,
  STUDENT_ADD_SUCCESS,
  STUDENT_UPDATE_FAILED,
  STUDENT_UPDATE_SUCCESS,
} from '../../constants/ErrorMessagesConstants'
import {toast} from 'react-toastify'

const useCreateStudent = () => {
  const queryClient = useQueryClient()
  const apiClient = new APIClientHooks('/students')
  return useMutation({
    mutationFn: (data: Student) => apiClient.postData(data),
    onSuccess: (data) => {
      toast.success(STUDENT_ADD_SUCCESS)
      queryClient.refetchQueries(['student'])
    },
    // onError: () => {
    //   toast.error(STUDENT_ADD_FAILED)
    // },

    onError: (error: any) => {
      const errorMessage = error.response?.data?.message
      toast.error(errorMessage)
    },
  })
}

const useFetchAllStudents = () => {
  const apiClient = new APIClientHooks<Student>('/students')
  return useQuery<Student[], Error>({
    queryKey: ['student'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: false,
  })
}

const useFetchStudentById = (id: number) => {
  const apiClient = new APIClientHooks<Student>(`/students`)
  return useQuery<Student, Error>({
    queryKey: ['students', id],
    queryFn: () => apiClient.getData(id),
  })
}

const useUpdateStudent = (id: number) => {
  const apiClient = new APIClientHooks<Student>('/students')
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: Student) => apiClient.updateData(id, data),
    onSuccess: (data, variables) => {
      // queryClient.invalidateQueries({queryKey: ['students', id]})
      queryClient.refetchQueries(['students', id])
      queryClient.refetchQueries(['studenttasks'])
      queryClient.refetchQueries(['studentclass'])
      queryClient.refetchQueries(['studentclasscount'])

      toast.success(STUDENT_UPDATE_SUCCESS)
    },
    // onError: () => {
    //   toast.error(STUDENT_UPDATE_FAILED)
    // },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.message
      toast.error(errorMessage)
    },
  })
}

export {useCreateStudent, useFetchAllStudents, useFetchStudentById, useUpdateStudent}
