import React, {useState} from 'react'
import {useFetchAllTasksOfClass} from '../../hooks/useTask'
import {useParams} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import {Task} from '../../../entities/UserEnities'
import CreateTask from '../tasks/CreateTask'
import TaskCard from '../tasks/TaskCard'
import {
  ADD_BUTTON,
  ADD_TASK,
  EDIT,
  EDIT_CLASS,
  NO_TASKS_FOUND_FOR_CLASS,
} from '../../../constants/AdminConstants'
import IconSquareButton from '../reusableComponents/IconSquareButton'
import {useFetchClassById} from '../../hooks/useClass'
import EditClass from './EditClass'
import {UNEXPECTED_ERROR} from '../../../constants/ErrorMessagesConstants'
import {PageTitleUpdater} from '../../../../_metronic/layout/components/header/PageTitleUpdater'
import {TeacherClassTasksBreadCrumbs} from '../../../breadcrumbs/breadcrums'

const ClassDetailPage = () => {
  const {id} = useParams()
  const classId = parseInt(id!)
  const {data: dataTasks, isLoading: isLoadingTasks, error} = useFetchAllTasksOfClass(classId)
  const {data: dataClass} = useFetchClassById(classId)

  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false)
  const [showEditClassModal, setShowEditClassModal] = useState(false)

  if (error) return <div> {UNEXPECTED_ERROR}</div>

  const toggleCreateTaskModal = () => {
    setShowCreateTaskModal(!showCreateTaskModal)
  }

  const toggleEditClassModal = () => {
    setShowEditClassModal(!showEditClassModal)
  }
  console.log('sss: ' + JSON.stringify(dataTasks))
  return (
    <>
      <PageTitleUpdater breadcrumbs={TeacherClassTasksBreadCrumbs}>
        {dataClass?.name}
      </PageTitleUpdater>
      <div>
        <div className='row'>
          <div className='col-4'>
            <h1>{dataClass?.name}</h1>
          </div>
          {/*Col-8 Starts here */}
          <div className='col-8 d-flex justify-content-end '>
            <div className='px-5'>
              <IconSquareButton
                path='/media/icons/duotune/add-svgrepo-com.svg'
                title='Click to add a Task'
                onClick={toggleCreateTaskModal}
                textButton={ADD_TASK}
              />
            </div>
            {/* <IconSquareButton
              path='/media/icons/duotune/pen-to-square-regular.svg'
              title='Click to Edit a Class'
              onClick={toggleEditClassModal}
              textButton={EDIT_CLASS}
            /> */}
          </div>
          {/*Col-8 End here */}
        </div>
        {isLoadingTasks ? (
          <Spinner />
        ) : (
          <div>
            {dataTasks !== null && dataTasks.length > 0 ? (
              <div>
                {dataTasks?.map((weekData) => (
                  <div className='mt-5 pt-5' key={weekData.week}>
                    <h1>Week: {weekData.week}</h1>
                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-1 mt-5'>
                      {weekData?.tasks?.map((task) => (
                        <div className='col' key={task.id}>
                          <TaskCard
                            description={task.description}
                            taskName={task.name}
                            completedSubtasks={0}
                            completedCounter={task.TasksAndStudents?.counter || 0}
                            totalCounter={task.total_counter}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className='d-flex justify-content-center mt-20'>
                <h1>{NO_TASKS_FOUND_FOR_CLASS}</h1>
              </div>
            )}
          </div>
        )}
        {/* <EditClass
          show={showEditClassModal}
          handleClose={toggleEditClassModal}
          userIdByRole={classId}
        /> */}
        <CreateTask show={showCreateTaskModal} handleClose={toggleCreateTaskModal} />
      </div>
    </>
  )
}

export default ClassDetailPage
