import {Spinner} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'
import {useFetchTeacherByUserId} from '../../hooks/useTeacher'
import TeacherTotalClassesChart from './teacherDashboard/TeacherTotalClassesChart'
import TeacherTotalStudentsChart from './teacherDashboard/TeacherTotalStudentsChart'

//TODO : Need an efficient way to fetch Teacher ID
const DashboardTeacher = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const userId = currentUser?.id!

  const {data: dataTeacher, isLoading: isLoadingTeacher} = useFetchTeacherByUserId(userId) //error here
  //TODO Would this API always work? Because without it the rest of the component won't work
  //?May put these in auth context?
  const teacherId = dataTeacher?.id!

  if (isLoadingTeacher) return <Spinner />

  return (
    <>
      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <TeacherTotalStudentsChart teacherId={teacherId} />
        </div>

        <div className='col-xxl-4'>
          <TeacherTotalClassesChart />
        </div>

        <div className='col-xxl-8'>
          {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' /> */}
        </div>
      </div>
    </>
  )
}

export default DashboardTeacher
