import {Controller} from 'react-hook-form'
import Select from 'react-select'
import {CustomSelectInputProps} from '../../../entities/PropEntities'
import {customStyleMultiValueSelectInput} from './Styles'
import {useState} from 'react'

const CustomSelectInput = <T,>({
  labelText,
  control,
  fieldName,
  rules,
  options,
  setValue,
  errorMessage,
  value,
  noOptionsText,
}: CustomSelectInputProps<T>) => {
  const [data, setData] = useState([])
  // function to handle the change of select
  const handleSelectChange = (newValue: any, actionMeta: any) => {
    const selectedValues = newValue.map((options: any) => options.value) // Assuming newValue is an array of selected option values
    setValue(fieldName, selectedValues) // Update the form field value using setValue
    if (!value) {
      setData(selectedValues)
    }
  }

  if (value) {
    const alreadyPresentValues = value.map((options: any) => options.value)
    setValue(fieldName, alreadyPresentValues)
  } else {
    setValue(fieldName, data) //?Why do we need it?
  }

  return (
    <div className='m-5'>
      <label className='form-label fs-6 text-dark fw-bolder'>{labelText}</label>

      <Controller
        name={fieldName}
        control={control}
        // rules={{required: true}}
        render={({field, fieldState}) => (
          <Select
            {...control.register(fieldName, rules)}
            styles={customStyleMultiValueSelectInput}
            isMulti
            options={options}
            onChange={handleSelectChange}
            className='basic-multi-select text-gray-100'
            classNamePrefix='select'
            defaultValue={value}
            required
            noOptionsMessage={({inputValue}) => (!inputValue ? noOptionsText : 'No results found')}
            {...(fieldState.error && <p className='text-danger'>{fieldState.error?.message}</p>)}
          />
        )}
      />
      {/* {control && fieldName && errorMessage && <p>{errorMessage}</p>} */}
    </div>
  )
}

export default CustomSelectInput
