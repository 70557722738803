import {Link} from 'react-router-dom'
import {IMG_DUMMY} from '../../../constants/AdminConstants'

interface CardUserProps {
  id?: number
  userId?: number
  image?: string
  name: string
  description: string | null // Depending on your data, description could be null
  userRoleLink: string
}

const CardUser = ({name, description, userRoleLink, id}: CardUserProps) => {
  return (
    <>
      <div className='card card-user mb-5 card-rounded'>
        <img src={IMG_DUMMY} className='card-img-top rounded-img mt-5 ' alt='' />
        <div className='card-body card-body-user'>
          <h2 className='card-title'>{name}</h2>
          <p className='card-text'>{description ? description : 'No Description'}</p>
          <Link to={`${userRoleLink}/${id}`} className='card-button-user'>
            <button className='btn btn-lg btn-primary'>View Report</button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default CardUser
