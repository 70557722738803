import {Control, RegisterOptions} from 'react-hook-form'
import {ZodString, ZodArray, ZodNumber, ZodOptional} from 'zod'
import {CustomInputProps} from '../../../../entities/PropEntities'

//TODO Convert the Number and String function into One
export function createZodValidationRule(schema: ZodString, fieldName: string) {
  return {
    validate: async (value: any) => {
      const result = schema.safeParse(value)
      if (result.success) {
        return true
      } else {
        return result.error.issues[0].message // Get the Zod error message
      }
    },
    message: `${fieldName} is not valid`,
  }
}

//! Changes made for Zod Optional.

export function createZodValidationRuleForOptional(
  schema: ZodOptional<ZodString>,
  fieldName: string
) {
  return {
    validate: async (value: any) => {
      const result = schema.safeParse(value)
      if (result.success) {
        return true
      } else {
        return result.error.issues[0].message // Get the Zod error message
      }
    },
    message: `${fieldName} is not valid`,
  }
}

//!

export function createZodValidationMultipleRule(
  schema: ZodArray<ZodString, 'many'>,
  fieldName: string
) {
  return {
    validate: async (value: any) => {
      const result = schema.safeParse(value)
      if (result.success) {
        return true
      } else {
        return result.error.issues[0].message
      }
    },
    message: `${fieldName} is not valid`,
  }
}

export function createZodValidationRequiredMultipleRule(
  schema: ZodArray<ZodString, 'many'>,
  fieldName: string
) {
  return {
    validate: async (value: any) => {
      const result = schema.safeParse(value)
      if (result.success) {
        if (result.data.length === 0 || result.data === null) {
          return 'Please select at least one week'
        }
        return true
      } else {
        return result.error.issues[0].message
      }
    },
    message: `${fieldName} is not valid`,
  }
}

export function createZodValidationRuleNumbers(schema: ZodNumber, fieldName: string) {
  return {
    validate: async (value: any) => {
      const result = schema.safeParse(Number(value))
      if (result.success) {
        return true
      } else {
        return result.error.issues[0].message // Get the Zod error message
      }
    },
    message: `${fieldName} is not valid`,
  }
}

const CustomInput = <T,>({
  labelText,
  type,
  placeholder,
  control,
  name,
  rules,
  errorMessage,
  value,
  setValue,
  maxNumber,
  readOnly,
}: CustomInputProps<T>) => {
  return (
    <>
      <div className='m-5'>
        <label className='form-label fs-6 text-dark fw-bolder'>{labelText}</label>
        <input
          type={type}
          className='form-control bg-transparent'
          placeholder={placeholder ? placeholder : ''}
          //? defaultValue vs Value
          defaultValue={value !== null ? value : ''}
          {...(control && name && rules && control.register(name, rules))}
          {...(type === 'number' && {min: 0, max: maxNumber})} //This checkout type
          {...(readOnly === true && {readOnly})} //This checkout type
        />

        {control && name && errorMessage && <p>{errorMessage}</p>}
      </div>
    </>
  )
}

export default CustomInput

/* <CustomInput<number>
                labelText='Age'
                type='number'
                control={control}
                name='age' // Field name
                rules={{
                  validate: (value) => {
                    return schemaAge.safeParse(Number(value)).success || 'Invalid age'
                  },
                }}
                errorMessage={errors.age ? errors.age?.message?.toString() : ''}
              /> //? Don't remove this from here */
// const schemaAge = z.number().min(10).max(12)
