import {Link} from 'react-router-dom'
import {ClassCardProp} from '../../../entities/ClassEntities'
import {useFetchAllTasksOfClass} from '../../hooks/useTask'
import {useAuth} from '../../../modules/auth'
import {UserRoles} from '../../../config/UserRoles'

const ClassCards = ({name, classId}: ClassCardProp) => {
  const {currentUser} = useAuth()
  let isAdmin = false
  if (currentUser?.user_role === UserRoles.admin) {
    isAdmin = true
  }

  return (
    <div className='p-5'>
      {isAdmin ? (
        <div className={`card gray-300`}>
          <div className='card-class d-flex align-items-center justify-content-center'>
            <span className=' card-class-text text-white opacity-75 pt-1 fw-bold fs-4'>{name}</span>
          </div>
        </div>
      ) : (
        <Link to={`/classes/${classId}`}>
          <div className={`card gray-300`}>
            <div className='card-class d-flex align-items-center justify-content-center'>
              <span className=' card-class-text text-white opacity-75 pt-1 fw-bold fs-4'>
                {name}
              </span>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}
export {ClassCards}
