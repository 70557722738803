import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import {formatNumber} from '../../../helpers/Helpers'
import {CountPillButtonProps} from '../../../entities/UserEnities'

const CountPillButton = ({AbsoluteURL, Text, CountData}: CountPillButtonProps) => {
  const formattedCount = formatNumber(CountData)

  return (
    <>
      <button className='btn btn-lg count-button-user'>
        <div className='row d-flex align-items-center'>
          <div className='col-3 px-0'>
            <SVG
              src={toAbsoluteUrl(AbsoluteURL)}
              className='count-button-icon-size count-button-icon-border'
            />
          </div>
          <div className='col-5'>
            <h3 className='my-0'>{Text}</h3>
          </div>
          <div className='col-4'>
            <h3 className='my-0'>{formattedCount}</h3>
          </div>
        </div>
      </button>
    </>
  )
}

export default CountPillButton
