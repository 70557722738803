import {TaskCardProps} from '../../../entities/PropEntities'
import CounterLabel from '../reusableComponents/CounterLabel'

const TaskCard = ({
  description,
  taskName,
  completedCounter,
  completedSubtasks,
  totalCounter,
}: TaskCardProps) => {
  return (
    <div className='p-5'>
      <div className={`card gray-300 task-card-container`}>
        <div className='task-card px-4 py-2'>
          <div className='align-items-start justify-content-start task-card-text'>
            <span className='  text-white opacity-75 fw-bold fs-4'>{taskName}</span>
          </div>
          <div className='task-card-description-body mt-3'>
            <p className='task-card-description-text text-white opacity-75 fs-7'>{description}</p>
          </div>
          <div>
            <div className='row pb-3 '>
              <div className='col-6 col-lg-12 col-xl-6  '>
                <div
                  className='d-flex align-items-center  justify-content-start  justify-content-lg-center
                justify-content-xl-start  py-0 py-lg-5 py-xl-0'
                >
                  <CounterLabel
                    text={'Subtasks'}
                    totalCount={3}
                    completedCount={completedSubtasks}
                    color={'counter-label-gray'}
                    textColor={'counter-label-text-gray'}
                  />
                </div>
              </div>
              <div className='col-6 col-lg-12 col-xl-6'>
                <div
                  className='d-flex align-items-center justify-content-end      justify-content-lg-center
                justify-content-xl-end  '
                >
                  <CounterLabel
                    text={'Counter'}
                    totalCount={totalCounter}
                    completedCount={completedCounter}
                    color={'counter-label-blue'}
                    textColor={'counter-label-text-blue'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskCard
