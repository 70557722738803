import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import APIClientHooks from '../../services/api-client-hooks'
import {TasksAndStudents, TasksGroupedByWeek} from '../../entities/UserEnities'
import {toast} from 'react-toastify'

//This fetches all the tasks of a Class By StudentID
const useFetchAllTasksOfClassByStudentId = (classId?: number, studentId?: number) => {
  const apiClient = new APIClientHooks<TasksGroupedByWeek>(
    `/taskandstudent?classId=${classId}&&studentId=${studentId}`
  )
  return useQuery<TasksGroupedByWeek[], Error>({
    queryKey: ['tasksclass'],
    queryFn: apiClient.getAllData,
    refetchOnWindowFocus: false,
  })
}

const useIncrementCounterTaskOfStudent = (id: number) => {
  const queryClient = useQueryClient()

  const apiClient = new APIClientHooks<TasksAndStudents>('/taskandstudent')
  return useMutation({
    mutationFn: (data: TasksAndStudents) => apiClient.updateData(id, data),
    onSuccess: () => {
      toast.success('Successfully incremented the counter')
      queryClient.refetchQueries(['tasksclass'])
    },
    onError: (error: any) => {
      const errorMessage = error.response?.data?.error
      toast.error(errorMessage)
    },
  })
}

export {useFetchAllTasksOfClassByStudentId, useIncrementCounterTaskOfStudent}
