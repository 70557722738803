import {useFetchAllClassesByTeacherId} from '../../hooks/useClass'
import {Spinner} from 'react-bootstrap'
import {ClassCards} from '../classes/ClassCards'
import {useAuth} from '../../../modules/auth'

//TODO: This will show all the classes
const TeacherClassesPage = () => {
  const {currentUser} = useAuth()

  const {data: dataClasses, isLoading: isLoadingClasses} = useFetchAllClassesByTeacherId()
  if (!currentUser?.user_role) return <div>Error: UserRole not found</div>
  if (isLoadingClasses) return <Spinner />

  return (
    <>
      <div>
        <div className='d-flex justify-content-between align-items-center '>
          <h1 className='my-0'>{currentUser.name} Classes</h1>
        </div>

        <div className='row row-cols-1  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-5 mt-5'>
          {dataClasses?.map((classdata) => (
            <div className='col' key={classdata.id}>
              <ClassCards name={classdata.name} classId={classdata.id} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TeacherClassesPage
