import {Spinner} from 'react-bootstrap'
import {useFetchAllTeachers} from '../../../hooks/useTeacher'
import CardUser from '../../reusableComponents/CardUser'
import {UNEXPECTED_ERROR} from '../../../../constants/ErrorMessagesConstants'
import {NO_TEACHERS_FOUND} from '../../../../constants/AdminConstants'

const ListViewTeacher = () => {
  const {data: teachersData, isLoading, error} = useFetchAllTeachers()

  if (isLoading) return <Spinner />
  if (!teachersData)
    return (
      <div className='mt-20 d-flex justify-content-center'>
        <h3> {NO_TEACHERS_FOUND}</h3>
      </div>
    )
  if (error) return <div>{UNEXPECTED_ERROR}</div>
  const userRoleLink = '/teacher'
  return (
    <>
      <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 g-5'>
        {teachersData?.map((teacher) => (
          <div className='col' key={teacher.id}>
            <CardUser
              name={teacher.User.name}
              description={teacher.description || ''} // Handle null description
              id={teacher.id}
              userRoleLink={userRoleLink}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default ListViewTeacher
