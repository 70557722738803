import Select from 'react-select'
import {CustomSelectInputProps} from '../../../entities/PropEntities'
import {Controller} from 'react-hook-form'
import {customStylesSingleValueSelectInput} from './Styles'

const CustomSelectSingleInput = <T,>({
  labelText,
  control,
  fieldName,
  rules,
  options,
  setValue,
  selectStatement,
  errorMessage,
  value,
}: CustomSelectInputProps<T>) => {
  //function to handle the change of select
  const handleSelectChange = (newValue: any, actionMeta: any) => {
    setValue(fieldName, newValue.value) // Update the form field value using setValue
  }

  // Create a modified options array with the "Select a teacher" option
  const modifiedOptions = [
    {value: '', label: selectStatement, isDisabled: true}, // Add the default option
    ...options, // Add the existing options
  ]

  return (
    <div className='m-5'>
      <label className='form-label fs-6 text-dark fw-bolder'>{labelText}</label>
      {/* <select
        {...control.register(fieldName, rules)}
        className='form-select select2-selection--multiple '
      >
        <option value=''>{selectStatement}</option>
        <option value={value.value} selected>
          {value.label}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value} className='my-5 py-5'>
            {option.label}
          </option>
        ))}
      </select>
      {control && fieldName && errorMessage && <p>{errorMessage}</p>} */}
      {/* //!It works and error message works too  */}
      <Controller
        name={fieldName}
        control={control}
        render={({field, fieldState}) => (
          <Select
            {...control.register(fieldName, rules)}
            // {...field}
            options={modifiedOptions}
            onChange={handleSelectChange}
            className='basic-single text-gray-100'
            classNamePrefix='select  '
            defaultValue={value}
            isMulti={false}
            styles={customStylesSingleValueSelectInput}
            {...(fieldState.invalid && <p className='text-danger'>{fieldState.error?.message}</p>)}
          />
        )}
      />
      {control && fieldName && errorMessage && <p>{errorMessage}</p>}

      {/*//!Error MEssage doesn't work with react-select*/}
      {/* {errors.selectedValues && <p>{errors.selectedValues.message}</p>} */}
    </div>
  )
}

export default CustomSelectSingleInput
