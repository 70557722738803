import {Link, useParams} from 'react-router-dom'
import {IMG_DUMMY} from '../../../../constants/AdminConstants'
import {
  useFetchCountAllClassesOfTeacher,
  useFetchCountAllStudentsOfTeacher,
  useFetchTeacherById,
} from '../../../hooks/useTeacher'
import CountPillButton from '../../reusableComponents/CountPillButton'
import {Spinner} from 'react-bootstrap'
import {TEACHER_DETAILS_NOT_FOUND} from '../../../../constants/ErrorMessagesConstants'
import {TeacherDataBreadCrumbs} from '../../../../breadcrumbs/breadcrums'
import {PageTitleUpdater} from '../../../../../_metronic/layout/components/header/PageTitleUpdater'

interface TeacherDetailsContainerProps {
  teacherId: number
}

const TeacherDetailsContainer = ({teacherId}: TeacherDetailsContainerProps) => {
  const {
    data: dataTeacher,
    error: errorTeacher,
    isLoading: isLoadingTeacher,
  } = useFetchTeacherById(teacherId)

  const {data: classesData} = useFetchCountAllClassesOfTeacher(teacherId)

  const {data: dataStudent} = useFetchCountAllStudentsOfTeacher(teacherId)

  if (isLoadingTeacher) return <Spinner />
  if (errorTeacher) return <div>{TEACHER_DETAILS_NOT_FOUND}</div>

  return (
    <>
      <PageTitleUpdater breadcrumbs={TeacherDataBreadCrumbs}>
        {dataTeacher ? dataTeacher.User.name : 'Teacher Data'}
      </PageTitleUpdater>

      <div className='d-flex justify-content-center'>
        <img src={IMG_DUMMY} className='teacher-detail-image rounded-circle mt-5 ' alt='' />
      </div>
      <div className='d-flex justify-content-center'>
        <h1 className='m-5'>{dataTeacher?.User.name}</h1>
      </div>
      <div className='row row-cols-1 row-cols-md-2 g-5 mx-5'>
        <div className='col d-flex justify-content-center justify-content-md-end '>
          <Link to={`/teacher/${dataTeacher.id}/all-classes`}>
            <CountPillButton
              AbsoluteURL='/media/teachers/ClassesIcon.svg'
              Text='Total Classes'
              CountData={classesData && classesData[0] ? classesData[0].total_classes || 0 : 0}
            />
          </Link>
        </div>
        <div className='col d-flex justify-content-center justify-content-md-start'>
          <Link to={`/teacher/${dataTeacher.id}/all-students`}>
            <CountPillButton
              AbsoluteURL='/media/teachers/StudentsIcon.svg'
              Text='Total Students'
              CountData={dataStudent && dataStudent.total_students ? dataStudent.total_students : 0}
            />
          </Link>
        </div>
      </div>
    </>
  )
}

export default TeacherDetailsContainer
