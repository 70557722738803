import {Spinner} from 'react-bootstrap'
import {ALL_CLASSES, NO_CLASS_FOR_TEACHER} from '../../../../constants/AdminConstants'
import {useFetchAllClasses} from '../../../hooks/useClass'
import {ClassCards} from '../../classes/ClassCards'
import {useParams} from 'react-router-dom'
import {PageTitleUpdater} from '../../../../../_metronic/layout/components/header/PageTitleUpdater'
import {generateBreadCrumbsForAllClassesOfTeacher} from '../../../../breadcrumbs/breadcrums'
import {ALL_CLASSES_BREADCRUMBS} from '../../../../constants/BreadcrumbsConstants'

const AllClassesOfTeacher = () => {
  const {id} = useParams()
  const teacherId = parseInt(id!)
  const {data: dataClasses, isLoading: isLoadingClasses} = useFetchAllClasses(teacherId)
  // const {data: dataTeacher} = useFetchTeacherById(teacherId)
  //TODO what id data teacher isn't availablE?

  return (
    <>
      {/* <PageTitleUpdater
        breadcrumbs={generateBreadCrumbsForAllClassesOfTeacher(
          dataTeacher?.User.name || 'Teacher Details',
          `/teacher/${teacherId}`
        )}
      >
        All Classes
      </PageTitleUpdater> */}
      <PageTitleUpdater
        breadcrumbs={generateBreadCrumbsForAllClassesOfTeacher(
          'Teacher Details',
          `/teacher/${teacherId}`
        )}
      >
        {ALL_CLASSES_BREADCRUMBS}
      </PageTitleUpdater>{' '}
      <div>
        <div className='d-flex justify-content-between align-items-center '>
          <h1 className='my-0'>
            {ALL_CLASSES}
            {/* of {dataTeacher?.User.name} */}
          </h1>
        </div>

        {isLoadingClasses ? (
          <Spinner />
        ) : (
          <>
            {dataClasses === undefined || dataClasses?.length === 0 || dataClasses === null ? (
              <div className='mt-20 d-flex justify-content-center'>
                <h3> {NO_CLASS_FOR_TEACHER}</h3>
              </div>
            ) : (
              <div className='row row-cols-1  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-5 mt-5'>
                {dataClasses?.map((classdata) => (
                  <div className='col' key={classdata.id}>
                    <ClassCards name={classdata.name} classId={classdata.id} />
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {/* {dataClasses?.length === 0 ? (
          <div className='mt-20 d-flex justify-content-center'>
            <h1> {NO_CLASS_FOR_TEACHER}</h1>
          </div>
        ) : (
          <div className='row row-cols-1  row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-5 mt-5'>
            {dataClasses?.map((classdata) => (
              <div className='col' key={classdata.id}>
                <ClassCards name={classdata.name} classId={classdata.id} />
              </div>
            ))}
          </div>
        )} */}
      </div>
    </>
  )
}

export default AllClassesOfTeacher
