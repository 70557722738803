import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'

type ButtonProps = {
  path: string
  title: string
  onClick: () => void
  textButton: string
}

const IconSquareButton = ({path, title, onClick, textButton}: ButtonProps) => {
  return (
    <div
      className='card-toolbar'
      data-bs-toggle='tooltip'
      data-bs-placement='top'
      data-bs-trigger='hover'
      title={title}
    >
      <a href='#' className='btn btn-sm btn-primary' onClick={onClick}>
        {textButton}
        <KTSVG path={path} className='svg-icon-3 ps-3' />
      </a>
      {/*//! svg-icon-3 got margin-right as 6, remove it TODO*/}
    </div>
  )
}

export default IconSquareButton
