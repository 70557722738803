//template

//General
export const DATA_ADD_SUCCESS = 'Data has been successfully added!'
export const DATA_ADD_FAILED = 'Oops! Something went wrong while adding the data.'
export const DATA_UPDATE_SUCCESS = 'Data has been updated successfully!'
export const DATA_UPDATE_FAILED = 'Oops! Something went wrong while updating the data.'

export const PASSWORD_CREATION_SUCCESS = 'Your password has been successfully created!'
export const PASSWORD_CREATION_FAILED = 'Oops! There was an issue while creating your password.'

export const UNEXPECTED_ERROR = 'Sorry, an unexpected error has occurred'

//Student
export const STUDENT_ADD_SUCCESS = 'Student has been successfully added!'
export const STUDENT_ADD_FAILED = 'Oops! Something went wrong while adding the Student.'
export const STUDENT_UPDATE_SUCCESS = 'Student data has been updated successfully!'
export const STUDENT_UPDATE_FAILED = 'Oops! Something went wrong while updating the Student data.'
export const STUDENT_DETAILS_NOT_FOUND =
  'Oops! Something went wrong we could not find Student data.'

//Teacher
export const TEACHER_ADD_SUCCESS = 'Teacher has been successfully added!'
export const TEACHER_ADD_FAILED = 'Oops! Something went wrong while adding the Teacher.'
export const TEACHER_UPDATE_SUCCESS = 'Teacher data has been updated successfully!'
export const TEACHER_UPDATE_FAILED = 'Oops! Something went wrong while updating the Teacher data.'
export const TEACHER_DETAILS_NOT_FOUND =
  'Oops! Something went wrong we could not find Teacher data.'
export const TEACHER_DATA_NOT_FOUND = 'No Teacher Data found'
//Class
export const CLASS_ADD_SUCCESS = 'Class has been successfully added!'
export const CLASS_ADD_FAILED = 'Oops! Something went wrong while adding the Class.'
export const CLASS_UPDATE_SUCCESS = 'Class data has been updated successfully!'
export const CLASS_UPDATE_FAILED = 'Oops! Something went wrong while updating the Class data.'
export const CLASS_DETAILS_NOT_FOUND = 'Oops! Something went wrong we could not find Class data.'

//Schema Validation Messages:
export const SELECT_NAME_MESSAGE = 'Please enter a valid name'
export const ENTER_EMAIL_MESSAGE = 'Please enter a valid email'
export const DESCRIPTION_MESSAGE_MIN =
  'Please enter a valid Description, there should be minimum 3 characters.'
export const DESCRIPTION_MESSAGE_MAX =
  'Please enter a valid Description, there should be maximum 110 characters.'
export const SELECT_TEACHER_MESSAGE = 'Please select a Teacher'
export const SELECT_COUNTER_MESSAGE = 'Please select at least one counter item'
export const SELECT_COUNTER_LIMIT_MESSAGE = ' You can select up to counter 3'
export const SELECT_WEEK_MESSAGE = 'Please select a week from 1 or later'
export const SELECT_WEEK_LIMIT_MESSAGE = 'You can select up to week 7'
export const SELECT_CLASS_TEACHER_ID_MESSAGE = 'PLease select a valid Teacher to assign'
export const SELECT_A_CLASS_MESSAGE = 'Please select at least one class'
export const SELECT_ATLEAST_ONE = 'Please select at least one option'
export const SELECT_OPTION_REQUIRED = 'Please select an option'

//Tasks
export const TASK_ADD_SUCCESS = 'Task has been successfully added!'
export const TASK_ADD_FAILED = 'Oops! Something went wrong while adding the Task.'

export const aVaa = ''
